<template>
  <template v-if="$device.isDesktop || noCarousel">
    <div class="collections-preview g-4" :class="{'no-carousel':noCarousel}">
      <CollectionPreview :image="collection.image" :slug="collection.slug"
                         :name="collection.name" :previews="collection.previews" v-for="collection in collections"/>
    </div>
  </template>
  <template v-else>
    <div class="carousel-container">
      <Carousel :items-to-scroll="1" :items-to-show="1.5" :items-to-show-="2.5" :items="collections">
        <template #default="{item}">
          <div class="slide">
            <CollectionPreview :image="item.image" :slug="item.slug"
                               :name="item.name" :previews="item.previews"/>
          </div>
        </template>
      </Carousel>
    </div>
  </template>
</template>

<script>
import CollectionPreview from "@/components/molecules/CollectionPreview.vue";
import Carousel from "~/components/molecules/Carousel.vue";

export default {
  props: ['collections', 'noCarousel'],
  components: {Carousel, CollectionPreview},
};
</script>

<style scoped lang="scss">
.collections-preview {
  grid-template-columns: repeat(4, 4fr) !important;
  width: 100%;
  display: grid;

  @include smd {
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 16px;
  }

  @include medium {
    grid-template-columns: repeat(3, 3fr) !important;
  }

  @include tablets {
    grid-template-columns: repeat(2, 2fr) !important;
  }
}

.no-carousel:deep(.related-collection-alternative) {
  @include smd {
    width: 100%;
  }

  .preview {
    @include smd {
      width: 58px;
      height: 58px;
    }
  }
}

.slide {
  margin-right: 20px;

  @include smd {
    margin-right: 0;
  }
}
</style>
